import { Add, Close, Edit, Info, TableView } from '@mui/icons-material';
import { Autocomplete, Box, Button, Grid, IconButton, Menu, TextField, Typography } from '@mui/material';
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid-premium';
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro';
import { useQuery } from '@tanstack/react-query';
import moment, { Moment } from 'moment';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchGet, fetchPost, LayoutContext } from 'wcz-layout';
import { CellClassName, GridDeleteCellItem, GridToolbar, GridToolbarProps, TableContainer } from 'wcz-x-data-grid';
import { LocationDialog } from '../components/cycleCount/LocationsDialog';
import PaginationFilter from '../models/base/PaginationFilter';
import PaginationResponse from '../models/base/PaginationResponse';
import { CreateCycleCount } from '../models/CycleCount';
import { CycleCountStatus } from '../models/enums/CycleCountStatus';
import { CycleCountType } from '../models/enums/CycleCountType';
import { MaterialCategory } from '../models/enums/MaterialCategory';
import Location from '../models/Location';
import Material from '../models/Material';
import { useCreateCycleCount, useDeleteCycleCount, useGetCycleCounts } from '../services/CycleCountService';
import { eqOrderUrl, eqRoomMaterialUrl, eqRoomUrl } from '../utils/BaseUrl';

interface Person {
    employeeId: string,
    nameTitleCase: string
}

const getMaterialByPnPaginationFilter = (value: string): PaginationFilter => {
    return {
        advancedFilter: {
            logic: "and",
            filters: [
                {
                    field: "partNumber",
                    operator: "contains",
                    value: value
                },
            ]
        },
        pageNumber: 0,
        pageSize: 12
    };
};

export default function CycleCounts() {
    const { changeTitle, t, i18n } = useContext(LayoutContext);
    const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);
    const [newCycleCount, setNewCycleCount] = useState<CreateCycleCount>({} as CreateCycleCount);
    const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);
    const [materials, setMaterials] = useState<Material[]>([]);
    const [selectedMaterials, setSelectedMaterials] = useState<Material[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const navigate = useNavigate();
    const [dateRange, setDateRange] = React.useState<DateRange<Moment>>([moment().add(-7, 'day'), moment()]);
    const [selectedCategories, setSelectedCategories] = useState<MaterialCategory[]>([]);
    const [locationDialogOpen, setLocationDialogOpen] = useState<boolean>(false);
    const [selectedPeople, setSelectedPeople] = useState<Person[]>([] as Person[]);

    useEffect(() => changeTitle(t("CycleCounts")), [i18n.language]);

    const { data = [], isLoading } = useGetCycleCounts();

    const remove = useDeleteCycleCount();

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'actions', type: 'actions', width: 100,
            getActions: (params: any) => [
                <GridDeleteCellItem key="remove" id={params.id} remove={remove} disabled={params.row.status !== CycleCountStatus.New} />,
                <GridActionsCellItem key="info" icon={params.row.status === CycleCountStatus.Confirmed ? <Info color="primary" /> : <Edit color="primary" />} label="Details" onClick={() => navigate(`/cycle-count/${params.id}`)
                } />,
            ],
        },
        { field: 'name', headerName: t("Name"), width: 220, },
        { field: 'type', headerName: t("Type"), width: 150, type: 'singleSelect', valueOptions: Object.values(CycleCountType), },
        {
            field: 'status', headerName: t("Status"), width: 150, type: 'singleSelect', valueOptions: Object.values(CycleCountStatus),
            cellClassName: (params) => params.row.status === CycleCountStatus.Confirmed && CellClassName.BackgroundSuccess
        },
        { field: 'startDate', headerName: t("StartDate"), width: 150, type: 'date', valueFormatter: ({ value }) => moment(value).formatDateTime() },
        { field: 'endDate', headerName: t("EndDate"), width: 150, type: 'date', valueFormatter: ({ value }) => moment(value).formatDateTime() },
        { field: 'confirmedDate', headerName: t("ConfirmedDate"), width: 150, type: 'date', valueFormatter: ({ value }) => moment(value).formatDateTime() },
    ] as GridColDef[], [i18n.language]);

    const { mutate: create, isLoading: isCreating } = useCreateCycleCount({
        onSuccess: data => navigate(`/cycle-count/${data.id}`)
    });

    const handleOnAdd = useCallback(() => {
        create({
            ...newCycleCount,
            locatedAtList: newCycleCount.type === CycleCountType.EmployeeCustom ? selectedPeople.map(p => p.employeeId) : selectedLocations.map(l => l.id),
            materials: selectedMaterials.map(m => m.id),
            dateFrom: newCycleCount.type === CycleCountType.DateRange ? moment(dateRange[0]?.add(1, "day")).toISOString() : undefined,
            dateTo: newCycleCount.type === CycleCountType.DateRange ? moment(dateRange[1]?.add(1, "day")).toISOString() : undefined,
            categories: selectedCategories
        });
    }, [newCycleCount, selectedLocations, selectedPeople, selectedMaterials, dateRange, selectedCategories]);

    const handleOnRowDoubleClick = useCallback((params: GridRowParams) => navigate(`/cycle-count/${params.id}`), []);

    const openContextMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        setContextMenu({ mouseX: e.clientX, mouseY: e.clientY, });
    }, []);

    const closeMenu = useCallback(() => {
        setContextMenu(null);
        setNewCycleCount({} as CreateCycleCount);
        setSearchValue("");
        setSelectedLocations([]);
        setSelectedMaterials([]);
        setSelectedPeople([]);
        setDateRange([moment().add(-7, 'day'), moment()]);
    }, []);

    useEffect(() => {
        if (newCycleCount.type === CycleCountType.Custom) {
            getLocations();
            getMaterials();
        } else if (newCycleCount.type === CycleCountType.EmployeeFull || newCycleCount.type === CycleCountType.EmployeeCustom) {
            getPeople();
        }
    }, [newCycleCount.type]);

    const { data: locations = [], refetch: getLocations } = useQuery<Location[]>(["locations"], ({ signal }) => fetchGet(`${eqRoomUrl}/v1/location`, signal), {
        enabled: false
    });

    const { refetch: getMaterials, isFetching: isFetchingMaterial } = useQuery<PaginationResponse<Material>>(["material", "search"], () => fetchPost(`${eqRoomMaterialUrl}/v1/material/search`, getMaterialByPnPaginationFilter(searchValue)), {
        enabled: false,
        onSuccess: data => setMaterials(data.content)
    });

    useEffect(() => { getMaterials(); }, [searchValue]);

    const { data: people = [], refetch: getPeople } = useQuery<Person[], Response>(["people"], ({ signal }) => fetchGet(`${eqOrderUrl}/v1/order/employees`, signal), {
        enabled: false
    });

    return (
        <React.Fragment>
            <TableContainer>
                <DataGridPremium rows={data} columns={columns} slots={{ toolbar: GridToolbar }} loading={isLoading} onRowDoubleClick={handleOnRowDoubleClick}
                    slotProps={{ toolbar: { items: [{ title: t("DataGrid.AddRecord"), onClick: openContextMenu, icon: <Add /> }] } as GridToolbarProps }} />
            </TableContainer>

            <Menu open={contextMenu !== null} anchorReference="anchorPosition" variant="menu"
                anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}>
                <Box sx={theme => ({ width: { xs: 300, sm: 320, md: 380, lg: 420, xl: 480 }, px: 2 })} role="presentation">
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h6">{t("NewCycleCount")}</Typography>
                        </Grid>

                        <Grid item>
                            <IconButton onClick={closeMenu}>
                                <Close fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Grid>


                    <TextField label={t("Name")} fullWidth variant="standard" value={newCycleCount.name} onChange={e => setNewCycleCount({ ...newCycleCount, name: e.target.value })} margin="dense" />

                    <Autocomplete
                        options={Object.values(CycleCountType)}
                        value={newCycleCount?.type ?? null}
                        autoHighlight
                        disableClearable
                        onChange={(e, value) => setNewCycleCount({ ...newCycleCount, type: value })}
                        renderInput={(params) => <TextField {...params} label={t("Type")} fullWidth variant="standard" margin="dense" required />}
                    />

                    {newCycleCount.type === CycleCountType.DateRange &&
                        <Fragment>
                            <DateRangePicker localeText={{ start: t("From")!, end: t("To")! }} value={dateRange} onChange={e => setDateRange(e)}
                                slotProps={{ textField: { variant: 'standard', margin: 'dense' } }} />

                            <Autocomplete
                                options={Object.values(MaterialCategory)}
                                value={selectedCategories}
                                multiple
                                autoHighlight
                                onChange={(e, value) => setSelectedCategories(value)}
                                renderInput={(params) => <TextField {...params} label={t("Category")} fullWidth variant="standard" margin="dense" multiline />}
                            />
                        </Fragment>
                    }

                    {newCycleCount.type === CycleCountType.Custom &&
                        <Fragment>
                            <Autocomplete
                                options={locations}
                                getOptionLabel={(option) => option.name}
                                value={selectedLocations ?? []}
                                multiple
                                autoHighlight
                                onChange={(e, value) => setSelectedLocations(value)}
                                renderInput={(params) => <TextField {...params} label={t("Locations")} fullWidth variant="standard" margin="dense" multiline />}
                            />
                            <Button size="small" onClick={() => setLocationDialogOpen(true)} startIcon={<TableView />}>{t("ChooseFromTable")}</Button>

                            <Autocomplete
                                options={materials}
                                getOptionLabel={(option) => option.partNumber}
                                value={selectedMaterials}
                                multiple
                                loading={isFetchingMaterial}
                                autoHighlight
                                onChange={(e, value) => setSelectedMaterials(value)}
                                onInputChange={(e, value) => setSearchValue(value)}
                                renderInput={(params) => <TextField {...params} label={t("Materials")} fullWidth variant="standard" margin="dense" multiline />}
                            />
                        </Fragment>
                    }

                    {newCycleCount.type === CycleCountType.EmployeeCustom &&
                        <Autocomplete
                            options={people}
                            getOptionLabel={(option) => `${option.nameTitleCase} (${option.employeeId})`}
                            value={selectedPeople}
                            multiple
                            autoHighlight
                            onChange={(e, value) => setSelectedPeople(value)}
                            renderInput={(params) => <TextField {...params} label={t("Employees")} fullWidth variant="standard" margin="dense" multiline />}
                        />
                    }

                    <Button variant="contained" onClick={handleOnAdd} sx={{ float: "right", my: 1 }} disabled={!newCycleCount.type || isCreating}>{t("Save")}</Button>
                </Box>
            </Menu>

            <LocationDialog open={locationDialogOpen} setLocationDialogOpen={setLocationDialogOpen} locations={locations} setSelectedLocations={setSelectedLocations} />
        </React.Fragment>
    );
}