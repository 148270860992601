import { Autocomplete, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from "react";
import { fetchGet, fetchPut, LayoutContext, TypographyWithIcon } from "wcz-layout";
import Item from "../../../models/Item";
import Material from "../../../models/Material";
import Order from "../../../models/order/Order";
import OrderItem from "../../../models/order/OrderItem";
import OrderItemLocation from "../../../models/order/OrderItemLocation";
import { OrderStatus } from "../../../models/order/OrderStatus";
import OrderItemMaterialItem from "../../../models/order/OrdetItemMaterialItem";
import { eqOrderUrl, eqRoomMaterialUrl } from "../../../utils/BaseUrl";
import { Cached } from "@mui/icons-material";

interface OrderItemDetailProps {
    order: Order,
    item: OrderItem,
    allOrders: Order[],
    setAllOrders: (allOrders: Order[]) => void,
    allMaterials: Material[],
    onRemainingChange: (itemId: string, remaining: number) => void;
}

export default function PurchaseOrderItem(props: OrderItemDetailProps) {
    const { item, order, allMaterials } = props;
    const [availableItems, setAvailableItems] = useState<Item[]>([] as Item[]);
    const [selectedItems, setSelectedItems] = useState<Item[]>([] as Item[]);
    const [storedItem, setStoredItem] = useState<OrderItem | null>(null);
    const { t } = useContext(LayoutContext);
    const material = useMemo(() => allMaterials.find(m => m.id === item.materialId), [allMaterials, item]);

    const { data: allItems } = useQuery<Item[]>(["items", "material", item.materialId], ({ signal }) => fetchGet(`${eqRoomMaterialUrl}/v1/item/material/${item.materialId}`, signal), {
        enabled: !!item.id,
        onSuccess: data => setAvailableItems(data.sort((a, b) => a.serialNumber.localeCompare(b.serialNumber)).filter(i => i.location?.name !== "HOLD" && i.location?.name !== "SCRAP" && !i.keeper)),
    });

    useEffect(() => {
        if (allItems)
            setSelectedItemsByItemsIds(item.materialItems);
    }, [item.materialItems, allItems]);

    useEffect(() => {
        initStoredItem();
    }, []);

    const initStoredItem = () => {
        const localStorageItems = Object.keys(localStorage);
        const foundItem = localStorageItems.find(i => i.includes(item.id!));

        if (foundItem) {
            const stringContent = localStorage.getItem(foundItem);
            if (stringContent)
                setStoredItem(JSON.parse(stringContent));

        } else {
            const today: string = moment().format('YYYY-MM-DD');
            localStorage.setItem(`${today}/orderItem/${item.id}`, JSON.stringify(item));
            setStoredItem(item);
        }

        clearOldStoredItems();
    };

    const clearOldStoredItems = () => {
        let localStorageItems = Object.keys(localStorage);
        localStorageItems = localStorageItems.filter(i => i.includes("orderItem"));

        localStorageItems.forEach(item => {
            const itemDate = moment(item.split("/")[0]);
            const currentDate = moment();
            const isOld: boolean = currentDate.diff(itemDate, 'week') > 1;
            if (isOld)
                localStorage.removeItem(item);
        });
    };

    useEffect(() => {
        if (!material?.hasItems) {
            material?.locations?.filter(l => l.name !== "SCRAP" && l.name !== "HOLD").forEach((location, index, array) => {
                if (index && array.length > 1)
                    return;

                const orderItemLocationQuantity = getOrderItemLocation(location.id)?.quantity;
                if (!orderItemLocationQuantity)
                    handleUpdateLocationQuantity(item.quantity, location.id);
            });
        }
    }, [material]);

    const setSelectedItemsByItemsIds = (items: OrderItemMaterialItem[]) => {
        const foundItems: Item[] = [];
        items?.forEach(materialItem => {
            allItems?.forEach(item => {
                if (materialItem.itemId === item.id)
                    foundItems.push(item);
            });
        });

        setSelectedItems(foundItems);
    };

    const { mutate: updateOrderItem } = useMutation((req: OrderItem) => fetchPut(`${eqOrderUrl}/v1/order/item/${req.id}`, req));

    const handleUpdateOrderItemId = (values: Item[]) => {
        const orderItem: OrderItem = {
            ...item,
            quantity: values.length,
            materialItems: values.map(i => {
                return {
                    itemId: i.id,
                    orderItemId: order.id,
                    returned: false
                } as OrderItemMaterialItem;
            })
        };

        updateOrderItem(orderItem);
        setSelectedItemsByItemsIds(orderItem.materialItems);
    };

    const getOrderItemLocations = (locations: OrderItemLocation[], quantity: number, locationId: string, orderItemId: string): OrderItemLocation[] => {
        const isInLocations: boolean = locations.map(l => l.locationId).includes(locationId);
        if (isInLocations) {
            return locations.map(location => {
                if (location.locationId === locationId) {
                    return { ...location, quantity: quantity };
                } else {
                    return location;
                }
            });
        } else {
            const addedOrderLocation: OrderItemLocation = {
                locationId: locationId,
                quantity: quantity,
                orderItemId: orderItemId,
                id: undefined
            };
            return [...locations, addedOrderLocation];
        }
    };

    const getOrderItems = (items: OrderItem[], quantity: number, locationId: string): OrderItem[] => {
        return items.map(item => {
            if (item.id === props.item.id) {
                const updatedItem: OrderItem = {
                    ...item,
                    quantity: quantity,
                    locations: getOrderItemLocations(item.locations, quantity, locationId, String(item.id))
                };
                updateOrderItem(updatedItem);
                return updatedItem;
            } else {
                return item;
            }
        });
    };

    const handleUpdateLocationQuantity = (quantity: number, locationId: string) => {
        if (quantity < 0)
            return;
        const updatedOrders: Order[] = props.allOrders.map(order => {
            if (props.item.orderId === order.id) {
                return {
                    ...order,
                    items: getOrderItems(order.items, quantity, locationId)
                };
            } else {
                return order;
            }
        });

        props.setAllOrders(updatedOrders);
    };

    const getOrderItemLocation = (locationId: string): OrderItemLocation | undefined => {
        return props.item.locations.find(l => l.locationId === locationId);
    };

    useEffect(() => {
        let remaining = 0;
        if (material?.hasItems) {
            remaining = (storedItem?.quantity ?? 0) - selectedItems.length;
        } else {
            const totalQuantity = item.locations.reduce((total, loc) => total + (loc.quantity ?? 0), 0);
            remaining = (storedItem?.quantity ?? 0) - totalQuantity;
        }
        props.onRemainingChange(item.id!, remaining);
    }, [selectedItems, storedItem, item.locations]);

    return (
        <Grid container sx={{ my: 3 }}>
            <Grid item xs={9}>
                <TypographyWithIcon endIcon={material?.exchange && <Cached color='primary' />}><b>PN:</b> {material?.partNumber}</TypographyWithIcon>
                <Typography>{material?.description}</Typography>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: 'right' }}>
                {material?.hasItems ?
                    <Typography>{selectedItems.length} {String(t("Pcs")).toLowerCase()} / {`${storedItem?.quantity ?? 0}`} {String(t("Pcs")).toLowerCase()}</Typography>
                    :
                    <Typography>{`${storedItem?.quantity ?? 0}`} {String(t("Pcs")).toLowerCase()}</Typography>
                }
            </Grid>

            <Grid item xs={12} sx={{ mt: 1 }}>
                {material?.hasItems ?
                    <div>
                        <Typography gutterBottom><b>{t("Locations")}:</b> {material?.locations?.filter(l => l.name !== "SCRAP" && l.name !== "HOLD").map(l => l.name).toString()}</Typography>

                        <Autocomplete
                            value={selectedItems}
                            onChange={(e, value) => handleUpdateOrderItemId(value)}
                            options={availableItems}
                            getOptionLabel={i => i.serialNumber}
                            autoHighlight
                            multiple
                            disabled={order.status !== OrderStatus.Created}
                            renderInput={(params) => <TextField {...params} label="SN" multiline size="small" sx={{ mb: 1 }} />}
                        />
                    </div>
                    :
                    material?.locations?.filter(l => l.name !== "SCRAP" && l.name !== "HOLD").map(location =>
                        <Grid item xs={12} key={location.id}>
                            <TextField
                                type="number"
                                size="small"
                                fullWidth
                                sx={{ my: 1 }}
                                value={getOrderItemLocation(location.id)?.quantity}
                                onChange={e => handleUpdateLocationQuantity(Number(e.target.value), location.id)}
                                InputLabelProps={{ shrink: true }}
                                disabled={order.status !== OrderStatus.Created}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography>{`${location.name} (${location.quantity} ${String(t("Pcs")).toLowerCase()})`}: </Typography>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    )
                }
            </Grid>
        </Grid>
    );
}